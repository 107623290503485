import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Air Freight
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Air Freight
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-12 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Air Freight.jpg"
                    style={{ minWidth: "100%" }}
                    alt=""
                  />
                </div>

                <div className=" p-4">
                  <h3 className="mb-0">Air Freight</h3>

                  <p>
                    <p>
                      {companyname} is widely recognized for its outstanding Air
                      Freight Forwarding services, characterized by efficient
                      execution and unwavering punctuality. Our team of seasoned
                      cargo professionals brings years of industry experience,
                      ensuring a smooth flow of communication and expedited
                      decision-making for all our clients.
                    </p>
                    <p>
                      We offer a diverse range of carriers, providing the
                      much-needed flexibility and reliability. Our reputation is
                      founded on trust and integrity, assuring you a transport
                      solution that is both cost-effective and time-sensitive.
                    </p>
                    <p>
                      The key features of our air freight forwarding services
                      include:
                    </p>
                    <p>
                      1. Air Freight Consolidations for Export and Import: We
                      specialize in consolidating air freight for both export
                      and import operations, optimizing efficiency and cost
                      reduction.
                    </p>
                    <p>
                      2. Special Rates for Bulk Air Freight Movements: We
                      provide competitive rates for bulk movements, enabling you
                      to achieve cost savings for large-scale shipments.
                    </p>
                    <p>
                      3. Collaborations with Leading Airlines: We have
                      established strong partnerships with major airlines,
                      ensuring seamless connectivity and comprehensive coverage
                      for all your air freight requirements.
                    </p>
                    <p>
                      4. Attractive Rates for Chartered Services: If you require
                      chartered air freight services, we offer attractive rates
                      tailored to your specific needs.
                    </p>
                    <p>
                      5. Flexible and Reliable Scheduling Options: Our services
                      are designed to accommodate your scheduling preferences,
                      providing flexibility while maintaining dependable
                      transportation solutions.
                    </p>
                    <p>
                      6. Enhanced Security Throughout Transit: We prioritize the
                      security of your cargo during transit, implementing
                      stringent measures to safeguard your shipments throughout
                      the entire journey.
                    </p>
                    <p>
                      At {companyname}, we take pride in delivering exceptional
                      air freight forwarding services that consistently meet and
                      surpass the expectations of our valued clients.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
