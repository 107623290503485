import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Contact Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center wow fadeInUp"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <h6 className="section-title bg-white text-center text-primary px-3">
              Contact Us
            </h6>
            <h1 className="mb-5">Contact For Any Query</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <h5>Get In Touch</h5>
              <p className="mb-4">
                Don't miss out on efficient logistics. Complete our freight
                forwarding contact form
              </p>
              <div className="d-flex align-items-center mb-4">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <i className="fa fa-map-marker-alt text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Office</h5>
                  <p className="mb-0">{companyaddress}</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <i className="fa fa-phone-alt text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Mobile</h5>
                  <p className="mb-0">{companynumber}</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <i className="fa fa-envelope-open text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Email</h5>
                  <p className="mb-0">{companyemail}</p>
                </div>
              </div>
            </div>
           
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInUp",
              }}
            >
              <form
                action="http://localhost/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>{" "}
                  <div className="col-12">
                    <div className="control-group">
                      <input
                        className="form-control p-4 "
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                        required
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="control-group">
                      <textarea
                        className="form-control p-4 "
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                      <p class="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary py-3 px-5  "
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Contact End */}
      <Footer />
    </>
  );
}
