import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
     {/* Footer Start */}
     <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-2 col-md-6">
              <h4 className="text-white mb-3">Company</h4>
              <Link className="btn btn-link" to="/">Home</Link>
              <Link className="btn btn-link" to="/About">About us</Link>
              <Link className="btn btn-link" to="/Whyus">Why Choose Us</Link>
              <Link className="btn btn-link" to="/Services">Services</Link>
              <Link className="btn btn-link" to="/Trading">Trading Services</Link>
              <Link className="btn btn-link" to="/Contact">Contact</Link>
              <Link className="btn btn-link" to="/Getquote">Request Quote</Link>
             
            </div>
            <div className="col-lg-2 col-md-6">
              <h4 className="text-white mb-3">Services</h4>
              <Link to="/Sea" className="btn btn-link">
                  Sea Freight
                  </Link>
                  <Link to="/Air" className="btn btn-link">
                  Air Freight
                  </Link>
                  <Link to="/Road" className="btn btn-link">
                  Road Freight
                  </Link>
                  <Link to="/Rail" className="btn btn-link">
                  Rail Freight
                  </Link>
                  <Link to="/Warehousing" className="btn btn-link">
                  Warehousing
                  </Link>
                  <Link to="/Hazardous" className="btn btn-link">
                  Hazardous Cargo
                  </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Contact</h4>
              <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />{companyaddress}</p>
              <p className="mb-2"><i className="fa fa-phone-alt me-3" />{companynumber}</p>
              <p className="mb-2"><i className="fa fa-envelope me-3" />{companyemail}</p>
              
            </div>
           
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Newsletter</h4>
              <p>Subscribe to Our Newsletter  </p>
              <div className="position-relative mx-auto" style={{maxWidth: '400px'}}>
              
                <form
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                 
                >
                  <input
                    className="form-control border-primary w-100 py-3 ps-4 pe-5"
                    onChange={(event) => handleSubemailChange(event)}
                    id="subemail"
                    value={subemail}
                    type="email"
                    name="subemail"
                    placeholder="Your email address"
                    required
                  />
                  <button className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2" type="submit">
                    Subscribe
                  </button>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                © <Link className="border-bottom" to="/">{companyname}</Link>, All Right Reserved.
              
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/Privacy">Privacy Policy</Link>
                  <Link to="/Terms">Terms & Conditions</Link>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
