import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Trading Services
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Trading Services
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container mt-5">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/Retail.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Trading Service
              </h6>
              <h1 className="mb-4">Retail</h1>
              <p className="mb-4">
                Retailers today face unprecedented challenges, driven by
                consumer awareness. If a product isn't available in-store,
                consumers can easily find it online, sometimes from different
                retailers. This applies to a wide range of products, from
                clothing and home goods to car parts, groceries, and
                perishables, as nearly everything can now be conveniently
                delivered to their doorstep. To keep pace with this evolving
                landscape, retailers must optimize their supply chains, reducing
                costs while meeting customer demands. A fully stocked store
                remains a significant competitive advantage, preventing
                potential sales loss to online or offline competitors. By
                partnering with {companyname}, retail businesses can enhance
                operational efficiencies, expedite store deliveries, and focus
                on delivering personalized customer service, something online
                retailers cannot truly replicate. This allows retailers to shift
                their focus from inventory concerns to providing an exceptional
                customer experience.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Trading Service
              </h6>
              <h1 className="mb-4">Food And Beverage</h1>
              <p className="mb-4">
                In the food supply and services industry, delays can have
                disastrous consequences. Rapid food spoilage goes beyond
                inconveniencing customers; it poses serious risks to consumers.
                At {companyname}, we understand the critical nature of timely
                and safe food logistics. Our teams, equipped with expertise and
                dedicated trucks, are committed to meeting all food and beverage
                shipping requirements. We prioritize the highest standards in
                food handling, ensuring food-grade facilities, proper loading,
                consolidation, and transportation practices. Our reliable and
                cost-effective solutions maximize value for your company,
                delivering the highest quality products to your customers.
              </p>
            </div>
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/Food And Beverage.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/Construction.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Trading Service
              </h6>
              <h1 className="mb-4">Construction</h1>
              <p className="mb-4">
                Efficient supply chain management is vital for smooth
                construction operations. A single delayed shipment, whether
                heavy equipment, raw materials, or tools, can significantly
                impede progress, resulting in costly delays. Entrusting your
                construction freight, supply chain management, and logistics
                needs to {companyname} is a wise choice. Since 1991, we have
                supported construction companies worldwide. We deliver what you
                require, precisely when and where you need it, helping you
                maintain seamless progress on your projects and avoid
                unnecessary setbacks.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Trading Service
              </h6>
              <h1 className="mb-4">Hospitality</h1>
              <p className="mb-4">
                In the hospitality industry, guest satisfaction is paramount.{" "}
                {companyname} offers comprehensive hotel logistics, hospitality
                supply chain management, and freight shipping services. We
                ensure you have everything necessary to provide guests with a
                seamless experience. Drawing on our decades of experience, we
                are the ideal partner for hotel and motel managers, event center
                operators, and anyone responsible for delivering exceptional
                guest service. Our commitment is to support you in delivering
                exceptional guest experiences while we handle logistics, supply
                chain management, and freight shipping.
              </p>
            </div>
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/Hospitality.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/Energy.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Trading Service
              </h6>
              <h1 className="mb-4">Energy</h1>
              <p className="mb-4">
                The energy industry faces numerous challenges, from updating
                aging infrastructure to exploring new fuel sources and managing
                increasing demand. Efficient supply chain management and
                logistics are essential to ensure power plants, oil wells, and
                renewable energy facilities operate at maximum capacity.
                {companyname} supports energy companies by shipping essential
                supplies, managing customs clearance, and providing project
                management solutions. With us as your logistics partner, you can
                rely on timely and reliable delivery to keep your operations
                running smoothly.
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                Trading Service
              </h6>
              <h1 className="mb-4">Automotive</h1>
              <p className="mb-4">
                The automotive industry demands precision and efficiency,
                requiring your logistics and supply chain management provider to
                embody these qualities. {companyname} excels in various aspects
                of automotive logistics, from efficient consolidation to
                meticulous inventory management. We ensure the seamless flow of
                automotive freight, from manufacturing to dealership. With our
                expertise, you can trust us to keep your automotive supply chain
                running smoothly, meeting the unique challenges and requirements
                of the industry with comprehensive and efficient solutions.
              </p>
            </div>
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/Automotive.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
