import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  {
    /* 
  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
 */
  }

  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <small className="me-3 text-light">
                <i className="fa fa-map-marker-alt me-2" />
                Kharghar, Navi Mumbai, 410210
              </small>
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2" />
                {companynumber}
              </small>
              <small className="text-light">
                <i className="fa fa-envelope-open me-2" />
                {companyemail}
              </small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <Link
                className="me-4"
                to="/Privacy"
              >
                Privacy Policy
              </Link>
              <Link
                
                to="/Terms"
              >
                Terms
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar & Hero Start */}
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <Link to className="navbar-brand p-0">
            
             <img src="img/logo.png" style={{height:"120px"}} alt="Logo"/> 
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link active">
                Home
              </Link>
              <Link to="/About" className="nav-item nav-link">
                About Company
              </Link>
              <Link to="/Whyus" className="nav-item nav-link">
                Why Us
              </Link>
             
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Freight Services
                </Link>
                <div className="dropdown-menu m-0">
                  <Link to="/Sea" className="dropdown-item">
                  Sea Freight
                  </Link>
                  <Link to="/Air" className="dropdown-item">
                  Air Freight
                  </Link>
                  <Link to="/Road" className="dropdown-item">
                  Road Freight
                  </Link>
                  <Link to="/Rail" className="dropdown-item">
                  Rail Freight
                  </Link>
                  <Link to="/Warehousing" className="dropdown-item">
                  Warehousing
                  </Link>
                  <Link to="/Hazardous" className="dropdown-item">
                  Hazardous Cargo
                  </Link>
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Trading Services
                </Link>
                <div className="dropdown-menu m-0">
                <Link to="/Trading" className="dropdown-item">
                Retail
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Food And Beverage
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Construction
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Hospitality
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Energy
                  </Link>
                  <Link to="/Trading" className="dropdown-item">
                  Automotive
                  </Link>
                </div>
              </div>
              <Link to="/Contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <Link to="/Getquote" className="btn btn-primary rounded-pill py-2 px-4">
              Request Quote
            </Link>
          </div>
        </nav>
       
      </div>
      {/* Navbar & Hero End */}
    </>
  );
};

export default Header;
