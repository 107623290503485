import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Hazardous Cargo
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Hazardous Cargo
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-12 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Hazardous Cargo.jpg"
                    style={{ minWidth: "100%" }}
                    alt=""
                  />
                </div>

                <div className=" p-4">
                  <h3 className="mb-0">Hazardous Cargo</h3>

                  <p>
                    {companyname} specializes in the global transportation
                    management of hazardous commodities. Our company,{" "}
                    {companyname}, is a well-established transport provider that
                    excels in the cross-border delivery of dangerous goods. We
                    proudly offer comprehensive transport services for hazardous
                    materials, covering all nine hazardous goods
                    classifications. These classifications encompass explosives,
                    gases, flammable liquids, flammable solids, oxidizing
                    chemicals, poisonous and infectious materials, radioactive
                    substances, corrosive substances, and various other
                    dangerous products. Consequently, our expertise extends to
                    handling acids, chemicals, fertilizers, motors, batteries,
                    radioactive isotopes, explosives, biological specimens,
                    cultures, pathological samples, and dry ice, among other
                    hazardous commodities. Our clientele includes esteemed
                    institutions such as universities, NGOs, and government
                    organizations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
