import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Warehousing
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Warehousing
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-12 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Warehousing.jpg"
                    style={{ minWidth: "100%" }}
                    alt=""
                  />
                </div>

                <div className=" p-4">
                  <h3 className="mb-0">Warehousing</h3>

                  <p>
                    <p>
                      {companyname} presents convenient and budget-friendly
                      warehousing solutions tailored for the storage of both
                      export and import cargo. Our extensive network of branch
                      offices and warehouses simplifies transportation
                      operations and fulfills the essential need for dependable
                      warehousing facilities.
                    </p>
                    <p>
                      Our clients can take advantage of our comprehensive
                      warehousing facility designed for the storage of goods,
                      whether for short-term or long-term durations. Equipped
                      with state-of-the-art security systems and climate control
                      measures, our warehouse guarantees the utmost safety and
                      protection of stored items.
                    </p>
                    <p>
                      Our dedicated team, consisting of packers, movers, and
                      professionally trained drivers, takes meticulous care of
                      our clients' possessions. They ensure the secure delivery
                      of goods, prioritizing the safe transportation of all
                      items from one location to another within the agreed-upon
                      timeframe.
                    </p>
                    <p>
                      Our cargo warehousing services encompass the following:
                    </p>
                    <p>
                      1. Sufficient Insured Private Warehousing Space: We offer
                      ample private warehousing space that is fully insured,
                      allowing our clients to store their goods with peace of
                      mind.
                    </p>
                    <p>
                      2. Warehouse Space at ICDs (Inland Container Depots): Our
                      warehousing space at ICDs is available for the storage of
                      goods that are carted directly at these centers, ensuring
                      efficient handling and streamlined operations.
                    </p>
                    <p>
                      3. Secure Goods Storage: Our warehouses provide a secure
                      and protected environment for the storage of goods,
                      safeguarding them against damage, theft, or other
                      potential risks.
                    </p>
                    <p>
                      4. Rigorous Control Checks for Goods Entry and Exit: We
                      implement strict control checks to monitor the entry and
                      exit of goods, ensuring precision and accountability
                      throughout the entire process.
                    </p>
                    <p>
                      By making use of our warehousing services, our clients can
                      reduce fixed overhead costs, enhance operational
                      efficiency, and save valuable management time. At{" "}
                      {companyname}, our commitment is to deliver dependable and
                      efficient warehousing solutions that cater to the diverse
                      needs of our clients.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
