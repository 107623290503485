import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                 Services
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                     Services
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
           {/* Package Start */}
           <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Our Services
            </h6>
            <h1 className="mb-5">Explore Our Freight Solutions</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Sea Freight</h3>

                  <p> {companyname} provides a wide range of ocean
                      transportation services, including ocean forwarding
                      consolidation and full container load (FCL) services.</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Sea"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Air Freight</h3>

                  <p> {companyname} is widely recognized for its outstanding Air
                      Freight Forwarding services, characterized by efficient
                      execution and unwavering punctuality.</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Air"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Road Freight.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Road Freight</h3>

                  <p> Road transportation offers a versatile means of
                      transporting goods, providing benefits such as speed,
                      cost-effectiveness, and flexibility </p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Road"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Rail Freight.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Rail Freight</h3>

                  <p>Rail freight presents sustainable transportation solutions
                      thanks to its reduced fuel consumption, particularly when
                      transporting substantial volumes or heavy cargo</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Rail"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/Warehousing.jpg" alt="" />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Warehousing</h3>

                  <p>{companyname} presents convenient and budget-friendly
                      warehousing solutions tailored for the storage of both
                      export and import cargo.</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Warehousing"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Hazardous Cargo.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Hazardous Cargo</h3>

                  <p> {companyname} specializes in the global transportation
                    management of hazardous commodities. Our company,{" "}
                    {companyname}, is a well-established transport provider</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Hazardous"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Package End */}
                  {/* Destination Start */}
                  <div className="container-xxl py-5 destination">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Trading Solutions
            </h6>
            <h1 className="mb-5">Explore Our Trading Solutions</h1>
          </div>
          <div className="row g-3">
            <div className="row g-3">
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Retail.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Retail
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img
                    className="img-fluid"
                    src="img/Food And Beverage.jpg"
                    alt=""
                  />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Food And Beverage
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img
                    className="img-fluid"
                    src="img/Construction.jpg"
                    alt=""
                  />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Construction
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Hospitality.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Hospitality
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Energy.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Energy
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Automotive.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Automotive
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Destination Start */}
      <Footer />
    </>
  );
}
