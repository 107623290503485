import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Rail Freight
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Rail Freight
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-12 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Rail Freight.jpg"
                    style={{ minWidth: "100%" }}
                    alt=""
                  />
                </div>

                <div className=" p-4">
                  <h3 className="mb-0">Rail Freight</h3>

                  <p>
                    <p>
                      Rail freight presents sustainable transportation solutions
                      thanks to its reduced fuel consumption, particularly when
                      transporting substantial volumes or heavy cargo in
                      land-locked regions, setting it apart from other shipping
                      methods. Furthermore, our rail freight solutions not only
                      provide pricing and timing flexibility but also deliver
                      environmental advantages.
                    </p>
                    <p>
                      As a prominent presence in the industry, we specialize in
                      offering diverse and cost-efficient rail freight solutions
                      through our expansive network. Supported by our team of
                      rail freight specialists and our dedicated fleet of
                      rolling stock, we provide comprehensive overland
                      rail-based solutions tailored for various industries.
                    </p>
                    <p>
                      Through collaborative efforts with national railways, we
                      offer a complete array of international, regional, and
                      domestic rail freight forwarding services, encompassing:
                    </p>
                    <p>
                      1. Conventional transportation utilizing covered or open
                      wagons.
                    </p>
                    <p>2. Transport of dry, bulk, and liquid cargo.</p>
                    <p>3. Containerized transport.</p>
                    <p>4. Handling oversized project cargo.</p>
                    <p>5. Transportation of ISO tanker containers.</p>
                    <p>6. Break-bulk transport.</p>
                    <p>
                      Through the utilization of our strong partnerships and
                      expertise, we ensure the provision of efficient and
                      dependable rail freight services tailored to meet our
                      clients' specific requirements.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
