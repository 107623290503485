import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
              Why Choose Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Why Choose Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Why us
            </h6>
            <h1 className="mb-5">Why Choose {companyname}</h1>
            <p>
              {companyname} is a committed transport and logistics
              company focused on building an efficient and cost-effective
              organization by implementing high-quality processes at every stage
              of our operations.
            </p>
          </div>
          <div className="row gy-5 gx-4 justify-content-center">
            <div
              className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i className="fa fa-eye fa-3x text-white" />
                </div>
                <h5 className="mt-4">Our Vision</h5>
                <hr className="w-25 mx-auto bg-primary mb-1" />
                <hr className="w-50 mx-auto bg-primary mt-0" />
                <p className="mb-0">
                  In a crowded industry, we stand out with our unique vision. We
                  seek to elevate the relocation experience through
                  professionalism, optimism, and excellence. {companyname} is committed to shaping a future of advanced and secure
                  packing and moving services, making a lasting impact in the
                  global market.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i className="fa fa-bullseye fa-3x text-white" />
                </div>
                <h5 className="mt-4">Our Mission</h5>
                <hr className="w-25 mx-auto bg-primary mb-1" />
                <hr className="w-50 mx-auto bg-primary mt-0" />
                <p className="mb-4">
                  We aim to connect with people and provide exceptional
                  services, continuously improving our offerings. Our goal is to
                  become a leading provider of customized relocation services,
                  driving us to excel and implement beneficial enhancements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* Destination Start */}
            <div className="container-xxl py-5 destination">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Trading Solutions
            </h6>
            <h1 className="mb-5">Explore Our Trading Solutions</h1>
          </div>
          <div className="row g-3">
            <div className="row g-3">
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Retail.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Retail
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img
                    className="img-fluid"
                    src="img/Food And Beverage.jpg"
                    alt=""
                  />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Food And Beverage
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img
                    className="img-fluid"
                    src="img/Construction.jpg"
                    alt=""
                  />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Construction
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Hospitality.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Hospitality
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Energy.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Energy
                  </div>
                </Link>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <Link
                  className="position-relative d-block overflow-hidden"
                  to="/Trading"
                >
                  <img className="img-fluid" src="img/Automotive.jpg" alt="" />

                  <div
                    className="bg-white text-primary fw-bold position-absolute bottom-0  m-3 py-1 px-2"
                    style={{ right: "40%" }}
                  >
                    Automotive
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Destination Start */}

      <Footer />
    </>
  );
}
