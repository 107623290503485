import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Sea Freight
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Sea Freight
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-12 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Sea Freight.jpg"
                    style={{ minWidth: "100%" }}
                    alt=""
                  />
                </div>

                <div className=" p-4">
                  <h3 className="mb-0">Sea Freight</h3>

                  <p>
                    <p>
                      {companyname} provides a wide range of ocean
                      transportation services, including ocean forwarding
                      consolidation and full container load (FCL) services. We
                      possess the expertise and capabilities to handle
                      international exports and imports, ensuring seamless and
                      effective operations for our customers.
                    </p>
                    <p>Our ocean freight services offer several advantages:</p>
                    <p>
                      1. Proficient Team: Our staff comprises experienced
                      professionals with in-depth knowledge of ocean
                      transportation, guaranteeing precise and efficient
                      handling of your shipments.
                    </p>
                    <p>
                      2. Flexible Scheduling: We acknowledge the significance of
                      adaptable scheduling options to cater to the distinctive
                      requirements of each shipment. We collaborate closely with
                      our clients to accommodate their specific timelines and
                      preferences.
                    </p>
                    <p>
                      3. Swift Transit Times: We provide expedited transit times
                      through our extensive network and strategic partnerships,
                      ensuring prompt delivery of your cargo to its intended
                      destination.
                    </p>
                    <p>
                      4. Competitive Pricing: We present competitive rates for
                      our ocean freight services, allowing our clients to
                      benefit from cost-effective transportation solutions
                      without compromising quality.
                    </p>
                    <p>Our ocean freight services encompass:</p>
                    <p>
                      - Global Export and Import: We facilitate the smooth
                      transportation of goods for both global export and import
                      operations.
                    </p>
                    <p>
                      - Less-than-Container-Load (LCL) Services: We offer LCL
                      services for shipments that do not require a full
                      container, providing cost-efficient options for smaller
                      cargo volumes.
                    </p>
                    <p>
                      - Full-Container-Load (FCL) Transport: Our FCL services
                      are tailored to shipments that necessitate a dedicated
                      container, ensuring efficient handling and direct
                      transportation.
                    </p>
                    <p>
                      - Special Project Handling: We possess the expertise to
                      manage and handle special projects, including oversized or
                      delicate cargo, offering customized solutions to meet
                      unique demands.
                    </p>
                    <p>
                      - Breakbulk Cargo: We can manage breakbulk cargo,
                      providing specialized handling and transportation
                      solutions for non-containerized shipments.
                    </p>
                    <p>
                      - Shipment Pre-Planning: We assist our clients in
                      pre-planning their shipments, optimizing routes, and
                      selecting the most suitable transportation options to
                      streamline the process.
                    </p>
                    <p>
                      - Cargo Tracking and Tracing: Our advanced tracking
                      systems enable real-time monitoring of cargo, providing
                      visibility and transparency throughout the transportation
                      journey.
                    </p>
                    <p>
                      - Purchase Order Management Tracking and Tracing: We offer
                      comprehensive tracking and tracing services for purchase
                      orders, ensuring efficient coordination and management of
                      the supply chain.
                    </p>
                    <p>
                      - Export Customs Clearances: We provide assistance with
                      export customs clearances, ensuring compliance with
                      regulatory requirements and smooth customs procedures.
                    </p>
                    <p>
                      At {companyname}, our commitment is to deliver dependable
                      and efficient ocean freight services, meeting the diverse
                      needs of our clients while upholding high service quality
                      standards.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
