import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Road Freight
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Road Freight
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-12 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Road Freight.jpg"
                    style={{ minWidth: "100%" }}
                    alt=""
                  />
                </div>

                <div className=" p-4">
                  <h3 className="mb-0">Road Freight</h3>

                  <p>
                    <p>
                      Road transportation offers a versatile means of
                      transporting goods, providing benefits such as speed,
                      cost-effectiveness, and flexibility for door-to-door
                      distribution services.
                    </p>
                    <p>
                      Our extensive logistics and road transport network
                      seamlessly connects major trade centers spanning greater
                      China, the CIS, the Baltics, and Europe. We leverage our
                      extensive fleet of fuel-efficient trucks, an advanced
                      fleet management system, an IT platform, strategically
                      positioned freight hubs, multilingual customer service
                      specialists, and a dependable network of partners to offer
                      efficient domestic, regional, and international road
                      freight transport solutions.
                    </p>
                    <p>
                      As a fully certified road freight service provider, we
                      offer an array of services:
                    </p>
                    <p>
                      1. LTL (Less Than Truckload), FTL (Full Truckload), and
                      break-bulk transportation services.
                    </p>
                    <p>
                      2. Transportation of heavy, oversized, and project cargo.
                    </p>
                    <p>
                      3. Customs bonded transportation, backed by the necessary
                      licensing.
                    </p>
                    <p>4. Dry, bulk, and liquid transportation.</p>
                    <p>5. Transportation of hazardous materials.</p>
                    <p>6. Transportation of perishable goods.</p>
                    <p>7. Household goods transportation.</p>
                    <p>8. Economic transport operator services.</p>
                    <p>9. Fleet management services.</p>
                    <p>10. GPS tracking services.</p>
                    <p>
                      By harnessing our expertise and resources, we are
                      dedicated to delivering efficient and dependable road
                      freight solutions customized to meet the unique needs and
                      requirements of our clients.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
