import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid bg-primary py-2 mb-5 hero-header"
        style={{ marginTop: "110px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
              About Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
            {/* About Start */}
            <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/about.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                About Us
              </h6>
              <h1 className="mb-4">
                Welcome to <span className="text-primary">{companyname}</span>
              </h1>
              <p className="mb-4">
                {companyname} is a company specializing in freight
                forwarding and trading solutions. Our primary focus is on
                building an efficient and cost-effective organization through
                the implementation of high-quality processes at every stage. Our
                main goal is to identify, meet, and surpass the needs of our
                customers by delivering top-notch services that provide
                excellent value for their money.
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-12">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Prioritizing customer satisfaction, both internally and
                    externally.
                  </p>
                </div>{" "}
                <div className="col-sm-12">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Enforcing a consistent human resource policy.
                  </p>
                </div>{" "}
                <div className="col-sm-12">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Maintaining a well-documented quality management system in
                    alignment with the necessary requirements.
                  </p>
                </div>{" "}
                <div className="col-sm-12">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Continuously improving our services and operational
                    practices.
                  </p>
                </div>
                <div className="col-sm-12">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2" />
                    Complying with all pertinent legal and statutory regulations
                    governing our industry.
                  </p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Why us
            </h6>
            <h1 className="mb-5">Why Choose {companyname}</h1>
            <p>
              {companyname} is a committed transport and logistics
              company focused on building an efficient and cost-effective
              organization by implementing high-quality processes at every stage
              of our operations.
            </p>
          </div>
          <div className="row gy-5 gx-4 justify-content-center">
            <div
              className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i className="fa fa-eye fa-3x text-white" />
                </div>
                <h5 className="mt-4">Our Vision</h5>
                <hr className="w-25 mx-auto bg-primary mb-1" />
                <hr className="w-50 mx-auto bg-primary mt-0" />
                <p className="mb-0">
                  In a crowded industry, we stand out with our unique vision. We
                  seek to elevate the relocation experience through
                  professionalism, optimism, and excellence. {companyname} is committed to shaping a future of advanced and secure
                  packing and moving services, making a lasting impact in the
                  global market.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i className="fa fa-bullseye fa-3x text-white" />
                </div>
                <h5 className="mt-4">Our Mission</h5>
                <hr className="w-25 mx-auto bg-primary mb-1" />
                <hr className="w-50 mx-auto bg-primary mt-0" />
                <p className="mb-4">
                  We aim to connect with people and provide exceptional
                  services, continuously improving our offerings. Our goal is to
                  become a leading provider of customized relocation services,
                  driving us to excel and implement beneficial enhancements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* Package Start */}
            <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Our Services
            </h6>
            <h1 className="mb-5">Explore Our Freight Solutions</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Sea Freight</h3>

                  <p> {companyname} provides a wide range of ocean
                      transportation services, including ocean forwarding
                      consolidation and full container load (FCL) services.</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Sea"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Air Freight</h3>

                  <p> {companyname} is widely recognized for its outstanding Air
                      Freight Forwarding services, characterized by efficient
                      execution and unwavering punctuality.</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Air"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Road Freight.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Road Freight</h3>

                  <p> Road transportation offers a versatile means of
                      transporting goods, providing benefits such as speed,
                      cost-effectiveness, and flexibility </p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Road"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Rail Freight.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Rail Freight</h3>

                  <p>Rail freight presents sustainable transportation solutions
                      thanks to its reduced fuel consumption, particularly when
                      transporting substantial volumes or heavy cargo</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Rail"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/Warehousing.jpg" alt="" />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Warehousing</h3>

                  <p>{companyname} presents convenient and budget-friendly
                      warehousing solutions tailored for the storage of both
                      export and import cargo.</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Warehousing"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="package-item">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/Hazardous Cargo.jpg"
                    alt=""
                  />
                </div>

                <div className="text-center p-4">
                  <h3 className="mb-0">Hazardous Cargo</h3>

                  <p> {companyname} specializes in the global transportation
                    management of hazardous commodities. Our company,{" "}
                    {companyname}, is a well-established transport provider</p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to="/Hazardous"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Learn More
                    </Link>
                    <Link
                      to="/Getquote"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0 30px 30px 0" }}
                    >
                      Get Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Package End */}

      <Footer />
    </>
  );
}
