import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header">
        <div className="container">
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: "400px" }}
          >
            <h3 className="display-4 text-white text-uppercase">
              Projects & Break Bulk
            </h3>
            <div className="d-inline-flex text-white">
              <p className="m-0 text-uppercase">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </p>
              <i className="fa fa-angle-double-right pt-1 px-3" />
              <p className="m-0 text-uppercase">Projects & Break Bulk</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-8">
              <div className="pb-3">
                <div className="blog-item">
                  <div className="position-relative">
                    <img
                      className="img-fluid w-100"
                      src="img/Projects & Break Bulk.jpg"
                      alt=""
                    />
                    <div className="blog-date">
                      <small className="text-white text-uppercase">
                        Service
                      </small>
                    </div>
                  </div>
                </div>
                <div className="bg-white mb-3" style={{ padding: "30px" }}>
                  <h2 className="mb-3">Projects & Break Bulk</h2>
                  <p>
                    <p>
                      {companyname} stands out in the meticulous management of
                      project cargo, a task that demands exceptional skill,
                      care, and attention. Our expertise extends across various
                      industries, including those that utilize heavy machinery,
                      oil and gas, construction, energy, maritime, mining, and
                      engines for both shoreside and maritime applications. We
                      have successfully executed projects in challenging and
                      remote locations with specialized solutions and an
                      unwavering commitment to ensuring smooth operations.
                    </p>

                    <p>
                      Whether your cargo falls under the categories of Break
                      Bulk (BB), Heavy Lift (HL), or Out of Gauge (OOG), we have
                      the capability to expertly handle complex transportation
                      operations. Choose {companyname} as your trusted partner
                      to oversee every aspect of your projects from start to
                      finish. Allow us to assist you in expanding the horizons
                      of your business goals and transforming your plans into
                      reality with our steadfast commitment and expertise.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="mb-5">
                <h4
                  className="text-uppercase mb-4"
                  style={{ letterSpacing: "5px" }}
                >
                  Other Services
                </h4>
                <div className="bg-white" style={{ padding: "30px" }}>
                  <ul className="list-inline m-0">
                    <li className="mb-3 d-flex justify-content-between align-items-center">
                      <Link className="text-dark" to="/Ocean">
                        <i className="fa fa-angle-right text-primary mr-2" />
                        Ocean Freight Forwarding
                      </Link>
                    </li>
                    <li className="mb-3 d-flex justify-content-between align-items-center">
                      <Link className="text-dark" to="/Air">
                        <i className="fa fa-angle-right text-primary mr-2" />
                        Air Freight Forwarding
                      </Link>
                    </li>
                    <li className="mb-3 d-flex justify-content-between align-items-center">
                      <Link className="text-dark" to="/Land">
                        <i className="fa fa-angle-right text-primary mr-2" />
                        Land Transportation
                      </Link>
                    </li>
                    <li className="mb-3 d-flex justify-content-between align-items-center">
                      <Link className="text-dark" to="/Warehousing">
                        <i className="fa fa-angle-right text-primary mr-2" />
                        Warehousing & Distribution
                      </Link>
                    </li>
                    <li className="mb-3 d-flex justify-content-between align-items-center">
                      <Link className="text-dark" to="/Multimodal">
                        <i className="fa fa-angle-right text-primary mr-2" />
                        Multimodal Transportation
                      </Link>
                    </li>
                    <li className="mb-3 d-flex justify-content-between align-items-center">
                      <Link className="text-dark" to="/Projects">
                        <i className="fa fa-angle-right text-primary mr-2" />
                        Projects & Break Bulk
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
